body {
    margin: 0;
    padding-top: 55px;
    overflow-y: scroll;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, "Microsoft YaHei", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: none;
    color: var(--semi-color-text-0) !important;
    background-color: var(--semi-color-bg-0) !important;
    height: 100%;
}

#root {
    height: 100%;
}

@media only screen and (max-width: 767px) {

    .semi-table-tbody,
    .semi-table-row,
    .semi-table-row-cell {
        display: block !important;
        width: auto !important;
        padding: 2px !important;
    }

    .semi-table-row-cell {
        border-bottom: 0 !important;
    }

    .semi-table-tbody>.semi-table-row {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    .semi-space {
        display: block !important;
    }
}

.semi-layout {
    height: 100%;
}

.tableShow {
    display: revert;
}

.tableHiddle {
    display: none !important;
}

body::-webkit-scrollbar {
    display: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.semi-navigation-vertical {
    /*display: flex;*/
    /*flex-direction: column;*/
}

.semi-navigation-item {
    margin-bottom: 0;
}

.semi-navigation-vertical {
    /*flex: 0 0 auto;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*width: 100%;*/
    height: 100%;
    overflow: hidden;
}

.main-content {
    padding: 4px;
    height: 100%;
}

.small-icon .icon {
    font-size: 1em !important;
}

.custom-footer {
    font-size: 1.1em;
}

@media only screen and (max-width: 600px) {
    .hide-on-mobile {
        display: none !important;
    }
}


.anchor-link {
    z-index: 999;
    padding: 20px 24px;
    background: #fff;
    box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.0400);
    border-radius: 16px;
    position: fixed;
    right: 24px;
    top: 80px;
    overflow: hidden;
    height: 56px;
    transition: height 0.25s linear;
}

.anchor-link-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    user-select: none;
    padding-bottom: 20px;
    color: #333;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.anchor-link-showall {
    height: 284px;
}

.ant-anchor-link {
    padding: 0;
    margin-top: 20px;
    text-align: left;
}

.ant-anchor-link a {
    color: rgba(0, 0, 0, 0.85);
    transition: color 0.25 linear;
    cursor: pointer;
}

.ant-anchor-link a:hover {
    color: #6262a0;
}

.language-change {
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #ddd;
    padding: 8px 2px 10px 8px;
    overflow: hidden;
    cursor: pointer;
    font-size: 13px;
    width: 78px;
    transition: width 0.25s linear;

    &:hover {
        width: 280px;
    }
}

.language-change-item {
    flex-shrink: 0;
    margin: 0px 8px;
}

.language-change-item:hover {
    color: rgba(0, 100, 250);
}

.language-change-item-active {
    color: rgba(0, 100, 250);
}

#driver-popover-title {
    text-align: center;
}

#driver-popover-content {
    max-width: 60%;
}

.driver-popover-prev-btn {
    display: none !important;
}

.semi-table-tbody>.semi-table-row>.semi-table-row-cell {
    text-align: left !important;
}

.apexcharts-legend-series {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.introduce-logo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
}

.introduce-logo img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
}

.introduce-logo .system-name {
    font-size: 24px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.introduce-model {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.introduce-model-list .semi-table-thead {
    background-color: transparent;
}

.introduce-model-list .semi-table-thead>.semi-table-row>.semi-table-row-head {
    background-color: transparent;
}

.system-tips .system-tips1 {
    color: red;
    font-weight: bold;
    text-align: left !important;
}

.system-tips2 {
    margin-bottom: 10px;
}

.siderbar-nav .semi-navigation-item-icon-info {
    margin-right: 8px;
}

.siderbar-nav .semi-icon-default {
    font-size: 19px;
}

.pc-header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100vw;
    height: 60px;
    background: #fff;
    z-index: 888;
    transition: all 250ms ease-in;
    box-shadow: 0px 4px 12px 1px rgba(0, 0, 0, 0.02);
    border-bottom: 1px solid var(--semi-color-border);
}

.MeunScroll {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0px 40px;
}

.MeunScroll .semi-icon-double_chevron_left,
.MeunScroll .semi-icon-double_chevron_right {
    cursor: pointer;
    font-size: 24px;
    color: #ddd;
}

.MeunScroll__left {
    flex-shrink: 0;
}

.MeunScroll__logo {
    height: 100%;
    display: flex;
    align-items: center;
}

.MeunScroll__logo img {
    height: 52px;
    margin-right: 2px;
}

.MeunScroll__name {
    font-size: 22px;
    font-family: Century Gothic;
    font-weight: 500;
}

.MeunScroll .line {
    width: 1px;
    height: 20px;
    background: #eee;
    margin-left: 24px;
    margin-right: 9px;
}

.MeunScroll_center {
    position: relative;
    flex: 1;
}

.MeunScroll_center_box {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    overflow: hidden;
    font-size: 14px;
    color: #333;
}

.MeunScroll_center_list {
    flex-shrink: 0;
    padding: 0px 15px;
    cursor: pointer;
}

.MeunScroll_center_list:hover {
    color: #6262a0;
    font-weight: 500;
}

.MeunScroll_center_bar {}

.bar-active {
    position: relative;
    color: #6262a0;
}

.bar-active::after {
    content: '';
    display: block;
    width: 26px;
    height: 4px;
    bottom: -20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    background: #6262a0;
    border-radius: 2px;
    transition: left 300ms ease-in-out;
}

.topup-left {
    margin-right: 20px;
    max-width: 560px;
    position: relative;
    border: 1px solid var(--semi-color-border);
    border-radius: 16px;
    overflow: hidden;
}

.topup-right {
    width: 360px;
    border: 1px solid var(--semi-color-border);
    border-radius: 16px;
    overflow: hidden;
}

.topup-left .semi-card-body {
    padding: 0px;
}

.topup-left .semi-image {
    height: 160px;
}

.topup-left .semi-avatar-wrapper {
    width: 78px;
    height: 78px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 120px);
}

.topup-right-yaoqing {
    position: relative;
    height: 36px;
    line-height: 36px;
    padding-right: 14px;
    padding-left: 10px;
    background: rgb(248, 250, 252);
    border-radius: 12px;
    border: 1px solid rgb(238, 242, 246);
    color: #999;
}

.topup-right .semi-button {
    width: 100%;
    margin-top: 24px;
}

.topup-right .semi-empty-footer {
    margin-top: 24px;
}

.topup-right-yaoqing-lael {
    text-align: center;
    margin-top: 24px;
    font-size: 14px;
    color: rgb(18, 25, 38);
    font-weight: 500;
    font-family: Roboto, sans-serif, Helvetica, Arial, sans-serif;
}